import React, { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import ContentLoader from 'react-content-loader';
import { useSearchParams } from 'react-router-dom';

import { isClassSubscriptionIncluded } from '../../../../../helpers';
import { icons } from '../../../../../helpers/icons';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import { getCourseAvailableTimes } from '../../../../../requests';
import {
  addPublicCourse,
  togglePublicCourse,
} from '../../../../../store/reducers/userSlice';
import { TAvailableTime, TCourse } from '../../../../../types';
import Tag from '../../../Tag';
import AvailableTimeItem from '../AvailableTimeItem';

interface ICourseAvailableTimes {
  course: TCourse;
}

const CourseAvailableTimes: React.FC<ICourseAvailableTimes> = ({ course }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const itemId = searchParams.get('itemId');

  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userReducer);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const { data: availableModules = [], isLoading } = useQuery({
    queryKey: ['courseAvailableTimes', course.templatePublicId],
    queryFn: async () => {
      const availableTimes = await getCourseAvailableTimes(
        course.templatePublicId,
      );
      if (itemId) {
        availableTimes.forEach((module, index) => {
          const item = module.availableTimes.find(({ id }) => id === itemId);
          if (item && !item.isBooked) {
            setActiveTabIndex(index);
            dispatch(addPublicCourse(mapToCartItem(item)));
          }
        });
      }
      setSearchParams(undefined);
      return availableTimes;
    },
  });

  const handleItemClick = (availableTime: TAvailableTime) => {
    dispatch(togglePublicCourse(mapToCartItem(availableTime)));
  };

  const mapToCartItem = (availableTime: TAvailableTime) => {
    const { imageUrl } = course;
    return {
      ...availableTime,
      course: { name: availableTime.name, imageUrl },
      included: isClassSubscriptionIncluded(
        availableTime.endTime,
        user?.program,
      ),
    };
  };

  return (
    <div className="side-info-card">
      <div className="flex items-center gap-2">
        <div className="shrink-0">
          <img src={icons.date} alt="" />
        </div>
        <div className="uppercase">
          {availableModules.length > 0 || isLoading
            ? 'Available times'
            : 'No upcoming classes'}
        </div>
      </div>
      {isLoading && (
        <ContentLoader className="max-h-12">
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="48" />
        </ContentLoader>
      )}
      {availableModules.length > 0 && (
        <>
          <div className="flex gap-4 pb-1 overflow-x-auto">
            {availableModules.map((module, index) => {
              const title = module.name === 'Cohort' ? null : module.name;
              return (
                <div
                  key={module.id}
                  className={`px-2 py-1 cursor-pointer transition-all whitespace-nowrap ${
                    activeTabIndex === index
                      ? 'text-blue-primary shadow-blue-underline'
                      : ''
                  }`}
                  onClick={() => setActiveTabIndex(index)}
                >
                  {title ?? 'Available Dates'}
                </div>
              );
            })}
          </div>
          <div className="flex flex-col gap-4">
            {!!availableModules[activeTabIndex].availableTimes.find(
              (availableTime) => availableTime.isBooked,
            ) && (
              <Tag
                text="You already have an active booking"
                color="yellow"
                className="self-start"
              />
            )}
            {availableModules[activeTabIndex].availableTimes.map(
              (availableTime) => (
                <AvailableTimeItem
                  key={availableTime.id}
                  availableTime={availableTime}
                  onClick={() => handleItemClick(availableTime)}
                />
              ),
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CourseAvailableTimes;
