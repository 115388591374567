import { useQuery } from '@tanstack/react-query';
import ContentLoader from 'react-content-loader';
import { useNavigate } from 'react-router-dom';

import ClassesNotFound from '../../../components/common/dashboard/ClassesNotFound';
import CourseCard from '../../../components/common/library/CourseCard';
import NavHeader from '../../../components/common/NavHeader';
import SubNavigation from '../../../components/common/SubNavigation';
import { NAVIGATE_PATH, NAVIGATION_INFO } from '../../../helpers/constants';
import { useAppDispatch } from '../../../hooks/hooks';
import { getPrivateCourses } from '../../../requests';
import { addPrivateCourse } from '../../../store/reducers/userSlice';
import { TPrivateCourse } from '../../../types';

const PrivateCourses = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { data: courses = [], isLoading } = useQuery({
    queryKey: ['privateCourses'],
    queryFn: getPrivateCourses,
  });

  const handleCourseBooking = (privateCourse: TPrivateCourse) => {
    const { name, imageUrl } = privateCourse.coursePreview;
    dispatch(
      addPrivateCourse({
        ...privateCourse.availableTime,
        course: { name, imageUrl },
        included: true,
      }),
    );
    navigate(NAVIGATE_PATH.checkoutSummary);
  };

  return (
    <>
      <NavHeader
        label="Private courses"
        className="!bg-grey-50 border border-b-1 border-grey-100"
      />
      <SubNavigation items={NAVIGATION_INFO.library.items} />
      <div className="min-h-screen main-container bg-grey-50">
        <div className="py-6 course-group-container">
          {isLoading ? (
            <ContentLoader className="private-course-card min-h-[20rem]">
              <rect x="0" y="0" rx="5" ry="0" width="100%" height="160" />
              <rect x="16" y="180" rx="5" ry="5" width="60%" height="28" />
              <rect x="16" y="228" rx="5" ry="5" width="88%" height="65" />
            </ContentLoader>
          ) : (
            !courses.length && (
              <ClassesNotFound
                title="There are no private classes to book at the moment"
                description="Please check with your company representative
                regarding available private classes with Learnit."
                buttonText="Discover public classes"
              />
            )
          )}
          {courses.map((course) => (
            <div key={course.availableTime.id} className="private-course-card">
              <CourseCard
                coursePreview={course.coursePreview}
                isBooked={course.availableTime.isBooked}
                onClick={() => handleCourseBooking(course)}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PrivateCourses;
