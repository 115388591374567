import { useQuery } from '@tanstack/react-query';

import CourseGroup from '../../../components/common/library/CourseGroup';
import CourseGroupSkeleton from '../../../components/common/library/CourseGroupSkeleton';
import CourseSearch from '../../../components/common/library/CourseSearch';
import CourseFilterButton from '../../../components/common/library/filter/CourseFilterButton';
import CourseFilters from '../../../components/common/library/filter/CourseFilters';
import NavHeader from '../../../components/common/NavHeader';
import SubNavigation from '../../../components/common/SubNavigation';
import { getUserNavigation } from '../../../helpers';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { getPublicCourses } from '../../../requests';
import { setCourses } from '../../../store/reducers/librarySlice';

const PublicCourses = () => {
  const dispatch = useAppDispatch();
  const { filteredCourses } = useAppSelector((state) => state.libraryReducer);
  const { user } = useAppSelector((state) => state.userReducer);

  const { isLoading } = useQuery({
    queryKey: ['publicCourses'],
    queryFn: async () => {
      const courses = await getPublicCourses();
      dispatch(setCourses(courses));
      return courses;
    },
  });

  const navigationItems = getUserNavigation(user).library.items;

  return (
    <>
      <NavHeader label="Learnit courses" />
      {user && navigationItems.length > 1 && (
        <SubNavigation items={navigationItems} />
      )}
      <div className="flex flex-col min-h-screen bg-grey-50">
        <CourseSearch />
        <div className="flex justify-end px-5 py-4 xl:hidden max-sm:px-2">
          <CourseFilterButton />
        </div>
        <div className="flex-row w-full h-full gap-5 pb-4 guest:guest-wrapper guest:mx-auto main-container max-sm:px-2 xl:guest:px-0 xl:py-10 bg-grey-50">
          <CourseFilters />
          <div className="w-full max-w-[980px] flex flex-col gap-10">
            {isLoading ? (
              <CourseGroupSkeleton />
            ) : (
              filteredCourses.map((courseGroup) => (
                <CourseGroup
                  key={courseGroup.groupName}
                  courseGroup={courseGroup}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PublicCourses;
